const INPUT_CONTAINER = '.text-input-component';
const INPUT_COMPONENT_CONTAINER = '.message-component';
const CSS_INVALID = 'invalid';
const CSS_INPUT_ERROR = 'input-error-message';

export class InputValidationRenderer {

  render(instruction) {
    for (let {
        result,
        elements
      } of instruction.unrender) {
      for (let elem of elements) {
        this.remove(elem, result)
      }
      if (!elements.length) {
        this.remove(null, result)
      }
    }

    for (let {
        result,
        elements
      } of instruction.render) {
      for (let elem of elements) {
        this.add(elem, result)
      }
      if (!elements.length) {
        this.add(null, result)
      }
    }
  }

  //note: result.valid is for that specific rule only, not for the property in general
  add(element, result) {
    if (result.valid) return;
    if (element === null && result && result.propertyName) {
      element = document.querySelector('#' + result.propertyName + 'InputField');
    }
    

    if (!element) return;

    /** When the binding is on a containerless element, pick parent */
    if (element.nodeType === 8 /*Node.COMMENT_NODE*/ ) {
      element = element.parentElement;
    }

    if (!element.closest) return;

    let inputField = element.closest(INPUT_CONTAINER) || element.querySelector(INPUT_CONTAINER) || element.closest(INPUT_COMPONENT_CONTAINER) || element.querySelector(INPUT_COMPONENT_CONTAINER);
    if (!inputField) return;

    inputField.classList.add(CSS_INVALID)

    //add message
    if (!inputField.querySelector('.' + CSS_INPUT_ERROR + '.' + result.rule.messageKey)) {
      const message = document.createElement('div');
      message.className = CSS_INPUT_ERROR + ' ' + result.rule.messageKey;
      message.textContent = result.message;
      message.id = `message-${result.id}`;
      inputField.appendChild(message);
    }

  }

  remove(element, result) {
    if (result.valid) return;

    if (element === null && result && result.propertyName) {
      element = document.querySelector('#' + result.propertyName + 'InputField');
    }

    if (!element) return;

    /** When the binding is on a containerless element, pick parent */
    if (element.nodeType === 8 /*Node.COMMENT_NODE*/ ) {
      element = element.parentElement;
    }

    if (!element.closest) return;

    let inputField = element.closest(INPUT_CONTAINER) || element.querySelector(INPUT_CONTAINER) || element.closest(INPUT_COMPONENT_CONTAINER) || element.querySelector(INPUT_COMPONENT_CONTAINER);
    if (!inputField) return;

    inputField.classList.remove(CSS_INVALID)
    const message = inputField.querySelector(`#message-${result.id}`);
    if (!message) return;

    inputField.removeChild(message);
  }

}
