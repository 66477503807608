/** @format */

import {
  customElement,
  inject,
  bindable
} from 'aurelia-framework';
import {
  MapService,
  TelemetryModes
} from 'services/map/mapService';

import './map.scss';

@customElement('map')
@inject(MapService)
export class Map {
  @bindable mapProvider;
  @bindable infoPanel;
  @bindable optionsPanel;
  @bindable recenterPanel;

  @bindable poi;
  @bindable zones;
  @bindable trip;
  @bindable route;
  @bindable eta;
  @bindable mapLayers;

  @bindable telemetry;
  @bindable telemetryOptions;

  @bindable assets;
  @bindable assetsUpdates;

  @bindable autoFitBounds;

  @bindable shapes;
  @bindable newShape;
  @bindable shapesEditable;

  @bindable onMapReady;

  @bindable center;
  @bindable centerZoom;
  @bindable centerBounds;

  @bindable maxZoom;
  @bindable draggable;
  @bindable zoomable;
  @bindable showLocationMarker;

  @bindable clustering;
  @bindable assetLabels;

  @bindable onDrawEnd;
  @bindable onOption;

  @bindable boundsPadding;

  constructor(_MapService) {
    this.mapService = _MapService;

    this.isReady = false;
    this.infoPanel = false;
    this.optionsPanel = false;
    this.recenterPanel = false;
    this.draggable = true;
    this.zoomable = true;
    this.autoFitBounds = true;

    this.clustering = true;
    this.assetLabels = false;

    this.maxZoom = 20;
    this.boundsPadding = [0, 0, 0, 0];

    this.telemetryOptions = {
      mode: TelemetryModes.trip,
      tooltips: true
    };

    this.showLocationMarker = false;

    this.mapTiles = [];
    this.onMapOptionsLayerSelected = this.onMapOptionsLayerSelected.bind(this);
    this.onMapOptionsTilesSelected = this.onMapOptionsTilesSelected.bind(this);

    this.panelInfo = null;
    this.onInfoPanelAction = this.onInfoPanelAction.bind(this);

    this.onMapRecenterClick = this.onMapRecenterClick.bind(this);
    this.onMapZoomClick = this.onMapZoomClick.bind(this);
  }

  attached() {
    this.initializeMap();
  }

  detached() {
    this.mapService.destroy();
  }

  centerChanged(center) {
    if (center && center.length) {
      let [lat, lng] = center;
      if (lat && lng) {
        this.mapService.panTo(lat, lng, this.centerZoom);
      }
    }
  }

  centerBoundsChanged(latlngs) {
    if (latlngs && latlngs.length) {
      this.recenterBounds = latlngs;
      this.mapService.fitBounds(latlngs);
    }
  }

  clusteringChanged(clustering, oldValue) {
    if (oldValue !== undefined && clustering !== undefined && this.isReady) {
      this.assetsChanged(this.assets);
      this.poiChanged(this.poi);
    }
  }

  assetLabelsChanged(value) {
    if (this.isReady && value !== undefined) {
      //refresh
      this.assetsChanged(this.assets);
    }
  }

  telemetryOptionsChanged() {
    if (this.isReady) {
      this.telemetryChanged(this.telemetry);
    }
  }

  tripChanged(newTrip) {
    this.mapService.setTelemetry(newTrip, this.autoFitBounds);
    this.recenterBounds = newTrip.map(nt => nt.latlng);
  }

  telemetryChanged(telemetry) {
    this.mapService.setTelemetry(
      telemetry,
      this.autoFitBounds,
      this.telemetryOptions
    );
    this.recenterBounds = (telemetry || []).map(t => t.latlng);
  }

  encodedPolylinesChanged(polylines) {
    this.mapService.setEncodedPolylines(polylines);
  }

  //can we merge assets and assetsUpdates by having a flag? like 'isLiveMode' or 'additive/editMode'?
  assetsChanged(newAssets) {
    let cluster = this.clustering.assets || this.clustering;
    let assets = (newAssets || []).slice(0);

    this.mapService.setFleet(assets, this.autoFitBounds, cluster);
    this.recenterBounds = assets.map(a => a.latlng);
  }

  assetsUpdatesChanged(assets) {
    if (assets) {
      this.mapService.updateFleet(assets);
    }
  }

  poiChanged(newPOI) {
    let cluster = this.clustering.poi || this.clustering;

    let pois = (newPOI || []).slice(0);
    this.mapService.setPOI(pois, cluster);
  }

  zonesChanged(newZones) {
    let zones = (newZones || []).slice(0);
    this.mapService.setZones(zones, true, false);
  }

  routeChanged(route) {
    this.recenterBounds = this.mapService.setRoute(route);
  }

  etaChanged(eta, old) {
    let diff = !old ||
      !eta ||
      eta.workerId !== old.workerId ||
      eta.polyline !== old.polyline;
    if (diff) {
      this.mapService.setETA(eta);
    }
  }

  shapesChanged(shapes) {
    let fitBounds = true;
    this.mapService.setShapes(shapes, this.shapesEditable, true, fitBounds);

    this.recenterBounds = this.mapService.shapesToArray(shapes);
  }

  shapesEditableChanged(editable) {
    if (this.mapLayers && this.mapLayers.length) {
      this.onMapOptionsLayerSelected(this.mapLayers.find(l => l.id == 'zones'))
    }
  }

  mapProviderChanged() {
    if (this.mapObj) {
      this.initializeMap();
    }
  }

  boundsPaddingChanged() {
    if (this.isReady) {
      this.mapService.setBoundsPadding(this.boundsPadding);
      if (this.recenterBounds && this.recenterBounds.length) {
        this.mapService.fitBounds(this.recenterBounds);
      }
    }
  }

  initializeMap() {
    this.mapService
      .create(this.mapelement, this.mapProvider, {
        center: {
          lat: 51.501904,
          lng: -0.137329
        },
        zoom: 3,
        draggable: this.draggable === true || this.draggable == 'true',
        zoomable: this.zoomable === true || this.zoomable === 'true',
        boundsPadding: this.boundsPadding,
        maxZoom: this.maxZoom
      })
      .then(instance => {
        this.isReady = true;
        if (typeof this.onMapReady === 'function') {
          this.initOptions();

          //TODO: refactor this into a mapService.addEvent instead (or something like that)
          this.mapService.onClick(this.onMapServiceClick.bind(this));

          this.onMapReady(instance);
        }
      });
  }

  onMapOptionsTilesSelected(layerId) {
    this.mapService.changeMapTiles(layerId);
    this.mapTiles = this.loadMapTiles(layerId);
  }

  onMapOptionsLayerSelected(layer) {
    this.mapService.toggleMapLayer(layer);
    this.mapLayers = this.mapService.getMapLayers();
  }

  initOptions() {
    this.mapTiles = this.loadMapTiles();
    this.mapLayers = this.mapService.getMapLayers();
  }

  loadMapTiles(id) {
    return this.mapService.getMapTiles(id);
  }

  onInfoPanelAction(action, location, address) {
    if (this.onOption) {
      this.onOption(action, {
        location,
        address
      });
    }
    if (action == 'closepanel') {
      this.updateLocationMarker(null);
    }
  }

  newShapeChanged(value) {
    this.editable = !!value;
    this.mapService.newShape(value);
  }

  //service handlers
  onMapServiceClick(location) {
    this.panelInfo = !this.panelInfo ? location : null;
    this.updateLocationMarker(this.panelInfo);
  }

  updateLocationMarker(location) {
    if (this.showLocationMarker) {
      this.mapService.setLocationMarker(location);
    }
  }
  //

  onMapRecenterClick() {
    if (this.recenterBounds && this.recenterBounds.length) {
      this.mapService.fitBounds(this.recenterBounds);
    }
  }

  onMapZoomClick(event, action) {
    if (!this.zoomable) return;
    let zoom = this.mapService.getZoom()
    switch (action) {
      case 'increase':
        if (zoom++ <= this.maxZoom) {
          this.mapService.setZoom(zoom++)
        }
        break;
      case 'decrease':
        if (zoom-- >= 1) {
          this.mapService.setZoom(zoom--)
        }
        break;
    }
  }
}
