import { bindable } from 'aurelia-framework';


import './map-zoom.scss';
export class MapZoom {

  @bindable onMapZoom;  

  constructor() {
    this.onMapZoomClick = this.onMapZoomClick.bind(this);
  }

  onMapZoomClick(e, action) {
    if (this.onMapZoom) {
      this.onMapZoom(e, action);
    }
  }
}
