import { inlineView } from 'aurelia-framework';
import PLATFORM from 'aurelia-pal';

@inlineView('<template><router-view containerless></router-view></template>')
export class ManageUsersPage {


  activate(params, config) {
    //a way to pass parent route settings to child settings - not working v
    this.router.parentSettings = config.settings;
  }

  configureRouter(config,router) {
    this.router = router;

    config.map([{
      route: '',
      name: 'users-list',
      title: '',
      moduleId: PLATFORM.moduleName('./manage-users-list')
      },{
        route: ':id',
        name: 'user-details',
        title: 'User details',
        moduleId: PLATFORM.moduleName('./manage-user-details')
      }
    ])
  }
}
