import { bindable, inject } from 'aurelia-framework';

import './map-infopanel.scss';

export class MapInfopanel {

  @bindable location;
  @bindable onAction;

  constructor() {

    this.open = false;
    this.location = null;

    this.address = null;
    this.svSize = [100,100];

    this.actions = [];

    this.onClose = this.onClose.bind(this);
    this.onAddressClick = this.onAddressClick.bind(this);

    this.onAddressLoad = this.onAddressLoad.bind(this);
  }

  locationChanged(value, oldValue) {
    this.open = !!this.location && !this.open;

    if (!this.open) {
      this.close();
    }
  }

  onClose() {
    this.close();
    this.fireAction('closepanel');
  }

  close() {
    this.open = false;
  }

  onAddressClick() {
    this.fireAction('location')
  }

  onAddressLoad(address) {
    this.address = address;
  }

  fireAction(action) {
    if(this.onAction) {
      this.onAction(action, this.location, this.address);
      this.close();
    }
  }
}
