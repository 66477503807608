import {
  inject,
  bindable,
  DOM,
  BindingEngine
} from 'aurelia-framework';
import {
  DialogService
} from 'aurelia-dialog';
import {
  MapLegendDialog
} from 'components/map/map-legend-dialog/map-legend-dialog';
import {
  MapSettingsDialog
} from 'components/map/map-settings-dialog/map-settings-dialog';

PLATFORM.moduleName('components/map/map-legend-dialog/map-legend-dialog');
PLATFORM.moduleName('components/map/map-settings-dialog/map-settings-dialog');

import './map-options.scss';

@inject(DOM.Element, BindingEngine, DialogService)
export class MapOptions {

  @bindable onTilesSelected;
  @bindable onLayerSelected;

  @bindable mapLayers;
  @bindable mapTiles;

  constructor(_DOMEl, _BindingEngine, _DialogService) {
    this._DOMEl = _DOMEl;
    this._BindingEngine = _BindingEngine;
    this.dialogService = _DialogService;

    this.showOptions = false;

    this.options = [{
        label: 'type',
        submenu: [],
        visible: true
      },
      {
        label: 'details',
        submenu: [],
        visible: true
      }
    ];

    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  attached() {
    document.addEventListener('click', this.onDocumentClick);
  }

  detached() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.showOptions) {
      this.onToggleOptions(e);
    }
  }

  onToggleOptions(e, toggle = true) {
    if (toggle) this.toggleOptions();

    e.stopImmediatePropagation();
    if (this.showOptions && toggle) {
      var divs = document.querySelectorAll('.clickable-dropdown.active');
      for (var i = 0; i < divs.length; ++i) {
        divs[i].click();
      };
    }
  }

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  optionsClick(e, option) {
    if (option.submenu) {
      let idx = 0;

      this.options.filter(x => x.visible).forEach((o, i) => {
        o.active = (o === option && !option.active);
        if (o.active) idx = i;
      });
    }

    if (option.action && !option.disabled) {
      option.action();
    }
  }

  onItemClick(e, action) {
    this.toggleOptions();

    switch (action) {
      case 'help':
        this.dialogService.open({
          viewModel: MapLegendDialog
        });
        return;
      case 'settings':
        this.dialogService.open({
          viewModel: MapSettingsDialog
        });
        return;
    }
  }

  mapTilesChanged(tiles = []) {
    let option = this.options[0];

    option.submenu = [];

    tiles.forEach(tile => {
      option.submenu.push({
        ...tile,
        label: tile.name,
        action: this.changeMapTiles.bind(this, tile.id),
        disabled: tile.active
      });
    });
  }

  mapLayersChanged(layers = []) {
    let option = this.options[1];

    option.submenu = [];

    layers.forEach(layer => {
      option.submenu.push({
        ...layer,
        label: layer.name,
        action: this.toggleMapLayer.bind(this, layer)
      });
    });

    option.visible = (option.submenu.length > 0);
  }

  toggleMapLayer(layer) {
    if (typeof this.onLayerSelected === 'function') {
      this.onLayerSelected(layer);
    }
  }

  changeMapTiles(id) {
    if (typeof this.onTilesSelected === 'function') {
      this.onTilesSelected(id);
    }
  }
}
