/** @format */

import { computedFrom } from 'aurelia-framework';
import userService, { User } from 'services/api/userService';
import provisionService from 'services/api/provisionService';
import eventService, { EventsList } from 'services/eventService';

export class ManageUsersList {
  constructor() {
    //
    this.userService = userService;
    this.provisionService = provisionService;

    this.gridHeaders = [
      { label: '', sortable: false, cssClass: 'image' },
      { label: 'name', sortable: true },
      { label: 'email', sortable: false }
    ];

    this.gridColumns = [
      { type: 'image', field: 'image', cssClass: 'image' },
      { type: 'text', field: 'name' },
      { label: 'text', field: 'email' }
    ];

    this.sortProperties = {
      field: 'name',
      reverse: false
    }

    this.selectedAccountId = null;
    this.isLoading = false;
    this.users = [];
    this.route = {};

    this.itemSelect = this.itemSelect.bind(this);
  }

  attached() {
    eventService.subscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );
  }

  detached() {
    eventService.unsubscribe(
      EventsList.PrivisionAccountChanged,
      this.onAccountChanged
    );
  }

  @computedFrom('users')
  get usersList() {
    return this.users;
  }

  activate(params, config) {
    this.router = config.navModel.router;
    this.route = config;
    //
    this.applyAccountFilter = !!this.router.parentSettings.enabledAccountFilter;
    if (this.applyAccountFilter) {
      this.selectedAccountId = this.provisionService.selectedAccountId;
    }

    this.loadUsers(this.selectedAccountId);
  }

  onAccountChanged = id => {
    if (!this.applyAccountFilter || !id) return;

    this.selectedAccountId = id;
    this.loadUsers(id);
  };

  loadUsers(accountId) {
    this.isLoading = true;
    this.userService.getUsers(null, accountId).then(users => {
      this.users = users;
      this.isLoading = false;
    });
  }

  itemSelect(item) {
    this.goToDetails(item);
  }

  onNewClick() {
    this.goToDetails(new User({ accountId: this.selectedAccountId }));
  }

  goToDetails(user) {
    let route = 'user-details';
    this.router.routes.find(x => x.name === route).settings = { user };
    this.router.navigateToRoute(route, { id: user.id || 'new' });
  }
}
