import {
  inject
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import {
  ConvertUtils
} from '@fonix/web-utils';
import MapIcons from 'services/map/mapIcons';

import './map-legend-dialog.scss';

@inject(DialogController)
export class MapLegendDialog {

  constructor(_DialogController) {
    this.dialogController = _DialogController;
  }

  get speedLimits() {
    let speedUnit = ConvertUtils.getUnit('speed') || {
      name: 'km/h'
    };
    let imperial = speedUnit.name == 'mph';
    return {
      min: imperial ? 30 : 50,
      max: imperial ? 60 : 100,
      unit: speedUnit.name
    };
  }

  getIcon(icon) {
    return MapIcons.get(icon);
  }

  onClose() {
    this.dialogController.close();
  }
}
