/** @format */

import {
  NewInstance
} from 'aurelia-framework';
import userService from 'services/api/userService';

import groupsService from 'services/api/groupsService';
import {
  ValidationRules,
  ValidationController,
  validateTrigger
} from 'aurelia-validation';
import {
  DialogService
} from 'aurelia-dialog';
import {
  Router
} from 'aurelia-router';
import {
  PromptDialog
} from 'components/prompt-dialog/prompt-dialog';

export class ManageUserDetails {
  static inject() {
    return [Router, NewInstance.of(ValidationController), DialogService];
  }
  constructor(_Router, _ValidationController, _DialogService) {
    //
    this.userService = userService;
    this.groupsService = groupsService;
    //
    this.dialogService = _DialogService;
    this.router = _Router;

    this.validationController = _ValidationController;
    this.validationController.validateTrigger = validateTrigger.manual;

    this.user = null;
    this.loading = false;
    this.errorMessage = null;

    this.rules = ValidationRules
      .ensure(o => o.username)
      .email()
      .required()
      .ensure(o => o.name)
      .required()
      .minLength(2)
      .withMessageKey('tooShort')
      .ensure(o => o.password)
      .required()
      .when(o => o.requirePassword)
      .minLength(8)
      .when(o => o.requirePassword)
      .withMessageKey('tooShort')
      .ensure(o => o.confirmPassword)
      .required()
      .when(o => o.requirePassword)
      .satisfies((value, d) => d.password === value)
      .withMessageKey('passwordsMatch')
      .when(o => o.requirePassword).rules;

    //new user
    this.toggleCreatePassword = false;
    this.toggleChangePassword = false;
    this.confirmPassword = null;

    this.canChangePassword = false;
    this.allowAccountChangePassword = true;
    this.canEditPermissions = false;
    this.rolesList = [];
    this.permissionsList = [];
    this.groups = [];

    this.onSave = this.onSave.bind(this);
    this.onGroupsSelection = this.onGroupsSelection.bind(this);
  }

  activate(params, cfg) {
    let {
      user
    } = cfg.settings;
    this.user = user;

    if (!this.user) {
      this.navigateBack();
      return;
    }

    this.loadData(params.id, user.accountId);
  }

  loadData(userId, accountId) {
    let pLoad = [];

    this.loading = true;

    pLoad.push(
      this.userService
      .getRolesList(accountId)
      .then(roles => (this.rolesList = roles))
    );

    pLoad.push(
      this.userService
      .getPermissionsList(accountId)
      .then(permissions => (this.permissionsList = permissions))
    );

    pLoad.push(
      this.groupsService
      .get(null, accountId)
      .then(groups => (this.groups = groups))
    );

    pLoad.push(this.loadUser(userId));

    pLoad.push(this.userService.getSelf());
    if (accountId) {
      pLoad.push(this.userService.getAccounts(accountId).then(([account]) => account));
    }

    Promise.all(pLoad)
      .then(([r, p, g, _user, self, account]) => {
        this.user = _user;
        let accountSettings = (account) ? account.settings : self.accountSettings;

        this.canChangePassword = !(_user.isAdmin && _user.accountId === 1);
        if (accountSettings && accountSettings.authImplementation == 'passwordless') {
          this.allowAccountChangePassword = false;
        }

        this.groups = this.groups.map(g => {
          g.selected = (this.user.groups || []).find(x => x.id === g.id);
          return g;
        });
        //cannot change self permissions
        this.canEditPermissions = self.id !== _user.id;

        this.loading = false;
      })
      .catch(e => {
        console.warn('Error loading user', e);
        this.navigateBack();
      });
  }

  onGroupsSelection(selected) {
    this.user.groups = selected.map(s => {
      return {
        id: s.id
      };
    });
  }

  loadUser(id) {
    if (id > 0) {
      return this.userService.getUsers(id);
    }
    return Promise.resolve(this.user);
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;
      //refactor (hold this in a dict)
      delete this.user.requirePassword;
      delete this.user.confirmPassword;

      return this.userService
        .updateUser(this.user)
        .then(() => {
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    //refactor
    //validations must be run in same obj as form, otherwise erros inputs do not work
    this.user.requirePassword =
      this.toggleCreatePassword || this.toggleChangePassword;
    this.user.confirmPassword = this.confirmPassword;

    return this.validationController
      .validate({
        object: this.user,
        rules: this.rules
      })
      .then(result => {
        console.debug('onValidate', result);
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onCancel() {
    this.navigateBack();
  }

  toggleEnabled() {
    let title = !this.user.isEnabled ? 'restore_user' : 'suspend_user';
    let message = !this.user.isEnabled ? 'restore_user_message' : 'suspend_user_message';
    let confirm = !this.user.isEnabled ? 'restore' : 'suspend';

    this.openPrompt(title, true, message, confirm).then(confirm => {
      if (!confirm) return;
      this.user.isEnabled = !this.user.isEnabled;
      this.onSave();
    });
  }

  deleteUser() {
    this.openPrompt('delete_user', true, 'delete_user_message', 'delete').then(confirm => {
      if (!confirm) return;
      this.loading = true;
      this.userService
        .delete(this.user.id)
        .then(() => {
          this.user.deleted = true;
          this.loading = false;
          this.navigateBack();
        })
        .catch(err => {
          this.errorMessage = err.message;
          this.loading = false;
        });
    });
  }

  openPrompt(title, question, message, confirm) {
    return new Promise(resolve => {
      this.dialogService
        .open({
          viewModel: PromptDialog,
          model: {
            title,
            question,
            message,
            confirm
          }
        })
        .whenClosed(result => {
          resolve(!result.wasCancelled);
        });
    });
  }

  onChangePasswordClick() {
    this.toggleChangePassword = !this.toggleChangePassword;
  }

  navigateBack() {
    this.router.navigateBack();
  }

  onRoleClick(e) {
    let role = e.detail.role;
    if (role.active && role.permissions.includes('modules.map')) {
      this.user.permissions.push('features.map.show-driver-address')
      this.user.permissions = this.user.permissions.map(p => p)
    }
  }
}
