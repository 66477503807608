import {
  inject,
  NewInstance
} from 'aurelia-framework';
import {
  DialogController
} from 'aurelia-dialog';
import {
  ValidationRules,
  ValidationController,
  ValidationMessageProvider
} from 'aurelia-validation';

import {
  ASSET_NAME_FORMATS
} from 'services/api/assetsService';
import userService from 'services/api/userService';

import './map-settings-dialog.scss';

@inject(DialogController, NewInstance.of(ValidationController))
export class MapSettingsDialog {

  constructor(_DialogController, _ValidationController) {
    this.dialogController = _DialogController;
    this.validationController = _ValidationController;

    this.userService = userService;
    this.assetNameFormats = ASSET_NAME_FORMATS;

    this.user = null;
    this.loading = false;
    this.errorMessage = null;
    this.rules = ValidationRules.rules;

    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  attached() {
    this.errorMessage = null;

    this.userService.getSelf().then(user => {
      this.user = user;
      this.editPreferences = Object.assign({}, user.preferences);
    });
  }

  onSave() {
    this.onValidate().then(result => {
      if (!result.valid) throw (result);
      this.loading = true;

      this.user.preferences = this.editPreferences;
      return this.userService
        .updateSelf(this.user)
        .then(_user => {
          this.loading = false;
          this.user = null;
          this.dialogController.ok(_user);
        })
        .catch(err => {
          this.loading = false;
          throw (err);
        });
    }).catch((err) => {
      this.errorMessage = (err && err.message) || 'errormessages--incompleteForms';
    });
  }

  onValidate() {
    return this.validationController
      .validate({
        object: this.user,
        rules: this.rules
      })
      .then(result => {
        return Promise.resolve(result);
      })
      .catch(err => {
        console.warn('onValidate Error', err);
        return Promise.resolve(false);
      });
  }

  onCancel() {
    this.dialogController.cancel();
  }
}
