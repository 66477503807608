/** @format */

import { containerless, bindable, TaskQueue, inject } from 'aurelia-framework';
import { lory } from 'lory.js';

import './lory-slider.scss';

@containerless
@inject(TaskQueue)
export class LorySlider {
  @bindable options;
  @bindable items;
  @bindable onSliderSlide;
  @bindable onSliderInit;
  @bindable height;

  @bindable charts;
  constructor(_TaskQueue) {
    this.taskQueue = _TaskQueue;
    this.initialized = false;
    this.lory = null;
    this.slideCls = '.js_slide';
  }

  attached() {
    if (this.onSliderInit) {
      this.onSliderInit(this);
    }
  }

  detached() {
    this._release();
  }

  init() {
    if (!this.refSlider) return;

    this.taskQueue.queueTask(() => {
      if (!this.refSlider) return;

      let slides = this.refSlider.querySelectorAll(this.slideCls);
      if (slides.length) {
        if (!this.lory) {
          this._attacheEvents();
          this.lory = lory(this.refSlider, this.options);
        } else {
          this.lory.setup(this.options);
        }
        this.initialized = true;
      }
    });
  }

  _release() {
    if (this.initialized && this.lory) {
      this.lory.destroy();
    }
  }

  _attacheEvents() {
    this.refSlider.addEventListener('after.lory.slide', e => {
      if (this.onSliderSlide) {
        this.onSliderSlide(e);
      }
    });
  }

  next() {
    if (this.initialized) {
      this.lory.next();
    }
  }

  prev() {
    if (this.initialized) {
      this.lory.prev();
    }
  }
}
